import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from '../components/atoms/Transition';

import GlobalStyles from '../components/atoms/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { theme } from '../utils/styling';
// import Alert from '../components/organisms/Alert';

class Index extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles/>
          <Transition location={this.props.location}>
            <main>
              {this.props.children}
            </main>
          </Transition>
          {/*<Alert/>*/}
        </>
      </ThemeProvider>
    )
  }
}


Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
